import { derived, writable, type Writable } from 'svelte/store';
import { isAdmin, userRoles } from 'shared/stores/user';
import { ROLES } from 'shared/config/user/roles';

import type { AppMenuStore } from './types';
import { appMenuItems } from '../config';
import { getAccesedMenuByUserRoles } from './menuItemsAccess';

class Store implements Writable<AppMenuStore> {
	public subscribe;
	public readonly set;
	public readonly update;

	constructor(data: AppMenuStore) {
		const { subscribe, set, update } = writable<AppMenuStore>(data);

		this.subscribe = subscribe;
		this.set = set;
		this.update = update;
	}

	setIsMenuRailEnabled(isMenuRailEnabled: AppMenuStore['isMenuRailEnabled']) {
		this.update((prev) => ({
			...prev,
			isMenuRailEnabled
		}));
	}

	setIsSideNavOpen(isSideNavOpen: AppMenuStore['isSideNavOpen']) {
		this.update((prev) => ({
			...prev,
			isSideNavOpen
		}));
	}

	updateStore(store: Partial<AppMenuStore>) {
		this.update((prev) => ({
			...prev,
			...store
		}));
	}
}

export const appMenuStore = new Store({
	isMenuRailEnabled: true,
	isSideNavOpen: true
});

export const appMenuItemsAccessed = derived(
	[userRoles, isAdmin],
	([userRolesValue, isUserAdminRole]) => {
		// Если пользователь админ, значит доступны все пункты меню
		if (isUserAdminRole) {
			return appMenuItems;
		}

		return getAccesedMenuByUserRoles(appMenuItems, userRolesValue as ROLES[]);
	}
);
