import { ROLES } from 'shared/config/user/roles';

import type { MenuItemGroup, MenuItem, MenuItemLink } from './types';

/**
 * @description Логика добавления ролей: Чтобы был показан пункт меню, можно просто добавить ему роли,
 * если нужно, чтобы показывалась группа добавляем ей роли.
 *
 * ВАЖНО: если добавить для группы (элемент, где есть items) роли и добавить для ссылки (где есть link)
 * разные роли, то если у пользователя есть роли назначенная для группы, будут показываться все дочерние элементы с их дочерними
 * элементами, тк у главного родителя назначена роль и она есть у юзера
 * если у родителя назначена роль, которой нет у пользователя, но для какой-то дочерней назначена роль, которая есть у юзера,
 * то будет показываться ссылка и родитель, тк в данном случае, чтобы увидеть ссылку нужно показать родителя.
 * Если назначить для группы роль, а у дочернего не будет роли, то будет браться роль назначенная родителем для провери показа ссылки.
 *
 * Не нужно дублировать роли для дочерних вкладок, достаточно назначить для родительской и все дочерние будут доступны по этой роли
 */
export const appMenuItems: MenuItemGroup[] = [
	{
		title: 'menu.group.administration',
		roles: [ROLES.AUTH_ADMIN],
		items: [
			{
				title: 'menu.section.auth',
				icon: import('carbon-icons-svelte/lib/UserMultiple.svelte'),
				roles: [ROLES.AUTH_ADMIN],
				items: [
					{
						title: 'menu.title.accounts',
						roles: [ROLES.AUTH_MANAGER],
						link: '/auth/accounts'
					},
					{
						title: 'menu.title.roles',
						link: '/auth/roles'
					},
					{
						title: 'menu.title.permissions',
						link: '/auth/permissions'
					}
				]
			},
			{
				title: 'menu.section.vitrina-wrapper',
				icon: import('carbon-icons-svelte/lib/WatsonHealthThumbnailPreview.svelte'),
				roles: [ROLES.VITRINA_EDITOR],
				items: [
					{
						title: 'menu.title.channels',
						link: '/vitrina'
					}
				]
			},
			{
				title: 'menu.section.dakilla',
				icon: import('carbon-icons-svelte/lib/AiGovernanceLifecycle.svelte'),
				roles: [ROLES.ADMIN],
				items: [
					{
						title: 'menu.title.dakilla.zones',
						link: '/dakilla/zones',
						icon: import('carbon-icons-svelte/lib/IbmCloudHyperProtectDbaas.svelte')
					},
					{
						title: 'menu.title.dakilla.banlist',
						link: '/dakilla/banlist',
						icon: import('carbon-icons-svelte/lib/EventIncident.svelte')
					}
				]
			},
			{
				title: 'menu.section.comments',
				icon: import('carbon-icons-svelte/lib/DocumentComment.svelte'),
				roles: [ROLES.COMMENTS_MODERATOR],
				items: [
					{
						title: 'menu.title.comments',
						link: '/comments'
					},
					{
						title: 'menu.title.threads',
						link: '/threads'
					}
				]
			}
		]
	},
	{
		title: 'menu.group.cms',
		roles: [ROLES.CMS_CHIEF_EDITOR],
		items: [
			{
				title: 'menu.section.content',
				icon: import('carbon-icons-svelte/lib/Book.svelte'),
				items: [
					{
						title: 'menu.title.news',
						roles: [ROLES.CMS_NEWS_EDITOR],
						link: '/cms/news'
					},
					{
						title: 'menu.title.videos',
						link: '/cms/videos'
					},
					{
						title: 'menu.title.series',
						link: '/cms/entity/series'
					},
					{
						title: 'menu.title.programs',
						link: '/cms/entity/program'
					},
					{
						title: 'menu.title.movies',
						link: '/cms/entity/movie'
					},
					{
						title: 'menu.title.seasons',
						link: '/cms/entity/seasons'
					},
					{
						title: 'menu.title.episodes',
						link: '/cms/entity/episodes'
					}
				]
			},
			{
				title: 'menu.section.structure',
				icon: import('carbon-icons-svelte/lib/DataStructured.svelte'),
				items: [
					{
						title: 'menu.title.categories',
						link: '/cms/categories'
					},
					{
						title: 'menu.title.tags',
						link: '/cms/tags'
					},
					{
						title: 'menu.title.supertags',
						link: '/cms/supertags'
					},
					{
						title: 'Рубрики',
						link: '/cms/rubrics'
					}
				]
			},
			{
				title: 'menu.section.admin',
				icon: import('carbon-icons-svelte/lib/InventoryManagement.svelte'),
				items: [
					{
						title: 'menu.title.logs',
						link: '/cms/logs'
					},
					{
						title: 'menu.title.blocker_locks',
						link: '/cms/blocker_locks'
					}
				]
			},
			{
				title: 'menu.section.mediamanager',
				icon: import('carbon-icons-svelte/lib/InventoryManagement.svelte'),
				items: [
					{
						title: 'Email fetcher',
						link: '/cms/mediamanager/email-fetcher/messages'
					},
					{
						title: 'MXF Files Finder',
						link: '/cms/mediamanager/mxf-files-finder'
					},
					{
						title: 'Task manager',
						link: '/cms/mediamanager/task_manager'
					},
					{
						title: 'Task manager settings',
						link: '/cms/mediamanager/task_manager_settings'
					},
					{
						title: 'Firebird',
						link: '/cms/mediamanager/firebird'
					}
				]
			},
			// Статические страницы
			{
				icon: import('carbon-icons-svelte/lib/Certificate.svelte'),
				title: 'menu.title.static_pages',
				link: '/cms/static-pages'
			}
		]
	},
	{
		title: 'menu.group.projects',
		roles: [ROLES.SPEC_QUIZ_EDITOR, ROLES.SPEC_QUIZ_EDA_EDITOR, ROLES.SPEC_QUIZ_CHUDO_EDITOR],
		items: [
			{
				icon: import('carbon-icons-svelte/lib/Gamification.svelte'),
				title: 'menu.title.quiz',
				link: '/quiz'
			},
			{
				icon: import('carbon-icons-svelte/lib/Earth.svelte'),
				title: 'menu.mir.title',
				roles: [ROLES.SPEC_MIR_EDITOR],
				items: [
					{
						title: 'menu.mir.schedule',
						link: '/mir/schedule'
					},
					{
						title: 'menu.mir.static-pages',
						link: '/mir/static-pages'
					}
				]
			}
		]
	},
	{
		title: 'menu.group.tools',
		roles: [ROLES.SMM],
		items: [
			{
				icon: import('carbon-icons-svelte/lib/Tools.svelte'),
				title: 'menu.title.external-video-streams',
				link: '/tools/external-video-streams'
			},
			{
				title: 'menu.title.cue',
				icon: import('carbon-icons-svelte/lib/InventoryManagement.svelte'),
				items: [
					{
						title: 'Player',
						link: '/cue/tsduck/player'
					},
					{
						title: 'Schedule',
						link: '/cue/tsduck/schedule'
					},
					{
						title: 'Settings',
						link: '/cue/tsduck/settings'
					},
					{
						title: 'Logs Kafka',
						link: '/cue/tsduck/sendlogs'
					},
					{
						title: 'Logs',
						link: '/cue/tsduck/scte_logs'
					}
				]
			},
			{
				title: 'ICC',
				icon: import('carbon-icons-svelte/lib/InventoryManagement.svelte'),
				link: '/tools/icc'
			},
			{
				title: 'Программы Firebird',
				icon: import('carbon-icons-svelte/lib/InventoryManagement.svelte'),
				link: '/tools/firebird/programs'
			}
		]
	},
	{
		title: 'menu.group.administration',
		roles: [ROLES.ADMIN],
		items: [
			{
				icon: import('carbon-icons-svelte/lib/Certificate.svelte'),
				title: 'menu.title.certificates',
				link: '/administration/certificates'
			}
		]
	}
];

export const isMenuItemGroup = (item: MenuItem): item is MenuItemGroup => 'items' in item;

export const isMenuItemLink = (item: MenuItem): item is MenuItemLink => 'link' in item;
