export enum ROLES {
	ADMIN = 'Admin',
	SMM = 'SMM',
	STAFF = 'Staff',
	VITRINA_EDITOR = 'vitrina.Editor',
	SPEC_QUIZ_EDITOR = 'spec.quiz.Editor',
	SPEC_QUIZ_EDA_EDITOR = 'spec.quiz.eda.Editor',
	SPEC_QUIZ_CHUDO_EDITOR = 'spec.quiz.chudo.Editor',
	AUTH_ADMIN = 'auth.Admin',
	AUTH_MANAGER = 'auth.Manager',
	CMS_CHIEF_EDITOR = 'cms.ChiefEditor',
	CMS_NEWS_EDITOR = 'cms.NewsEditor',
	COMMENTS_MODERATOR = 'comments.Moderator',
	SPEC_MIR_EDITOR = 'spec.mir.Editor'
}
