import type { CarbonTheme } from 'carbon-components-svelte/types/Theme/Theme.svelte';
import { APP_THEME_KEY, indexDb } from 'shared/indexDbDexie';
import { appThemeStore, defaultTheme } from 'shared/stores/app/stores/theme';
import { get } from 'svelte/store';

export const persistTheme = async (value: CarbonTheme) => {
	appThemeStore.updateTheme(value);

	await indexDb.settings.update(APP_THEME_KEY, { key: APP_THEME_KEY, value });
};

export const initTheme = async () => {
	const storedSettings = await indexDb.settings.toArray();
	const storedTheme = storedSettings.find((s) => s.key === APP_THEME_KEY);

	appThemeStore.updateTheme(
		storedTheme ? (storedTheme.value.toString() as CarbonTheme) : defaultTheme
	);

	if (!storedTheme) {
		await indexDb.settings.add({
			key: APP_THEME_KEY,
			value: get(appThemeStore)
		});
	}
};
