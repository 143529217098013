import axios, { type AxiosRequestConfig } from 'axios';
import { baseHeaders } from './lib';

/**
 * @description Чтобы прокидывать кастомные переменные
 */
declare module 'axios' {
	export interface AxiosRequestConfig {
		isRefreshToken?: boolean;
		silentErrorStatuses?: HttpStatusCode[];
		slientCanceledError?: boolean;
		isSignIn?: boolean;
		isLogout?: boolean;
		ignore403?: boolean;
		__retry?: boolean;
	}
}

const axiosConfig: AxiosRequestConfig = {
	silentErrorStatuses: [],
	headers: {
		...baseHeaders()
	},
	withCredentials: true
};

export const API = axios.create({
	...axiosConfig
});
