import { MENU_RAIL_KEY, indexDb } from 'shared/indexDbDexie';
import { appMenuStore, type AppMenuStore } from 'shared/stores/app/stores/menu';
import { get } from 'svelte/store';

export const persistMenuRail = async (isMenuRailEnabled: AppMenuStore['isMenuRailEnabled']) => {
	await indexDb.settings.update(MENU_RAIL_KEY, { key: MENU_RAIL_KEY, value: isMenuRailEnabled });

	appMenuStore.setIsMenuRailEnabled(isMenuRailEnabled);
};

export const initAppMenu = async () => {
	const storedSettings = await indexDb.settings.toArray();
	const storedMenuRail = storedSettings.find((s) => s.key == MENU_RAIL_KEY);

	if (!storedMenuRail) {
		await indexDb.settings.add({
			key: MENU_RAIL_KEY,
			value: get(appMenuStore).isMenuRailEnabled
		});

		return;
	}

	appMenuStore.setIsMenuRailEnabled(Boolean(storedMenuRail.value));
};
