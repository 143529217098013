import Dexie, { type Table } from 'dexie';
import type { StoredParam } from './types';

export class MySubClassedDexie extends Dexie {
	settings!: Table<StoredParam>;

	constructor() {
		super('hub_db');
		this.version(1.101).stores({
			settings: '&key'
		});
	}

	async init() {
		this.isStoragePersisted()
			.then(async (isPersisted) => {
				if (isPersisted) {
					console.log('Storage is persisted.');
				} else {
					console.log('Storage is not persisted.');
					console.log('Trying to persist..:');
					if (await this.persist()) {
						console.log('Successfully turned the storage to be persisted.');
					} else {
						console.log('Failed to make storage persisted');
					}
				}
			})
			.catch((error) => {
				console.error(error, 'error when indexdb dexie init');
			});
	}

	async persist() {
		return await (navigator.storage && navigator.storage.persist && navigator.storage.persist());
	}

	async isStoragePersisted() {
		return await (navigator.storage &&
			navigator.storage.persisted &&
			navigator.storage.persisted());
	}
}

export const indexDb = new MySubClassedDexie();
