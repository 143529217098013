import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { COOKIE_KEYS_AUTH } from 'shared/config/cookie';
import { userStore } from 'shared/stores/user';
import type { DecodedTokenData } from 'shared/auth/@types/token';
import { setTokenAndUserStore } from './lib';
import { authJWTCookie } from '../jwt';

/**
 *
 * @param token JWT access token
 * @param refreshToken JWT refresh token
 * @description Декодирует токен и устанавливает данные в стор пользователя
 * @returns
 */
export const decodeTokenAndSetDataStore = (accessToken: string | undefined) => {
	if (!accessToken) {
		return;
	}

	try {
		const decodedTokenData = jwtDecode(accessToken) as DecodedTokenData;

		if (!decodedTokenData) {
			console.error('Нет данных после декодирования токена', decodedTokenData);

			return null;
		}

		setTokenAndUserStore(accessToken, decodedTokenData);

		return decodedTokenData;
	} catch (error) {
		console.error(error, 'error when update local token data decoded token');

		return null;
	}
};

/**
 * @description Установить данные из куки для пользователя
 * @returns
 */
export const setTokenDataFromCookie = () =>
	decodeTokenAndSetDataStore(Cookies.get(COOKIE_KEYS_AUTH.accessToken));

/**
 * @description Очистить данные пользователя
 */
export const clearAuthDataUser = () => {
	userStore.removeToken();
	userStore.removeUser();

	authJWTCookie.removeTokens();
};
