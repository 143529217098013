import Cookies from 'js-cookie';
import { appStore } from 'shared/stores/app';

export const APP_STORE_LAYOUT_MODE_COOKIE_KEY = 'layout_mode';

export const initLayoutMode = () => {
	const layoutModeCookie = Cookies.get(APP_STORE_LAYOUT_MODE_COOKIE_KEY) || 'default';

	appStore.setLayoutMode(layoutModeCookie);
};
