import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { detect } from 'detect-browser';
import { Mutex } from 'async-mutex';

/**
 * @description Глобальный промис для индикации возможности запросов
 */
export const apiRequestMutex = new Mutex();

export const baseHeaders = () => ({
	'X-Platform': 'website',
	'Content-Type': 'application/json'
});

export const baseBody = async () => {
	const browser = detect();
	const fpPromise = FingerprintJS.load();
	const fp = await fpPromise;
	const fpResult = await fp.get();
	const deviceId = fpResult.visitorId;
	const deviceType = `${browser?.os} ${browser?.name}`;

	return {
		device_type: deviceId,
		device_id: deviceType
	};
};

export const getBearerTokenHeader = (token?: string) => (token ? `Bearer ${token}` : '');
