import { derived, writable, get, type Readable } from 'svelte/store';
import type { IUser } from 'shared/api/models/User';
import { ROLES } from 'shared/config/user/roles';

import { isAccessRoleService, isHasRole } from './lib';
import type { UserStore } from './types';
import type { ITokenData } from 'shared/api/models/Token';

class Store implements Readable<UserStore> {
	public subscribe;

	private readonly update;

	constructor(value: UserStore) {
		const { update, subscribe } = writable<UserStore>(value);

		this.subscribe = subscribe;
		this.update = update;
	}

	setUser(user: IUser) {
		this.update((prev) => ({
			...prev,
			user
		}));
	}

	removeUser() {
		this.update((prev) => ({
			...prev,
			user: null
		}));
	}

	setToken(accessToken: string, token: ITokenData) {
		this.update((prev) => ({
			...prev,
			accessToken,
			decodedAccessToken: token
		}));
	}

	removeToken() {
		this.update((prev) => ({
			...prev,
			decodedAccessToken: null,
			accessToken: null
		}));
	}
}

export const userStore = new Store({
	user: null,
	decodedAccessToken: null,
	accessToken: null
});

export const tokenData = derived(userStore, (data) => data.decodedAccessToken);

export const userData = derived(userStore, (data) => data.user);

export const userRoles = derived(userData, (data) => data?.roles || []);

export const isAdmin = derived(userRoles, (roles) => isHasRole(roles, ROLES.ADMIN));

/**
 *
 * @description Доступ по роле, или админ
 */
export function isAccessRole(role: ROLES[]): boolean;
export function isAccessRole(role: ROLES): boolean;
export function isAccessRole(role: ROLES | ROLES[]): boolean {
	const isAdminVal = get(isAdmin);

	if (Array.isArray(role)) {
		return role.some((item) => isAccessRoleService(isAdminVal, get(userRoles), item));
	}

	return isAccessRoleService(isAdminVal, get(userRoles), role);
}
