import { ROLES } from 'shared/config/user/roles';

import { isMenuItemGroup, type MenuItem, type MenuItemGroup } from '../config';

const hasAccessMenuItem = (itemRoles: ROLES[], roles: ROLES[]) =>
	itemRoles.some((role) => roles.includes(role));

const getMenuItemAccess = (item: MenuItem, userRoles: ROLES[], isParentAccessed?: boolean) => {
	// Если родительский пункт меню или раздел разрешен, значит все дочерние также разрешены
	if (isParentAccessed) {
		return true;
	}

	// Если родительский не разрешен и у дочернего нет ролей, значит дочерний также не разрешен
	if (isParentAccessed === false && !item.roles?.length) {
		return false;
	}

	// Если родительский не определен и у дочернего нет ролей, значит разрешен
	if (!item.roles?.length) {
		return true;
	}

	// Если родительский не определен или не разрешен и у дочернего есть роли, проверяем роли
	return hasAccessMenuItem(item.roles, userRoles);
};

/**
 *
 * @param item Текущий элемент меню с дочерними элементами
 * @param isAccessed Текущий доступ для пункта меню
 * @param isParentAccessed Доступ для родительского пункта
 */
const getMenuItemParentAccess = (
	item: MenuItemGroup,
	isAccessed: boolean,
	isParentAccessed: boolean | undefined
): boolean | undefined => {
	// Если парент не разрешен и у текущего пункта нет ролей, то пункт нельзя показывать
	if (!isParentAccessed && !item.roles) {
		return false;
	}

	// Если нет ролей, то возвращаем undefined, тк пока не определен доступ
	if (!item.roles) {
		return undefined;
	}

	// Возвращаем уже определенную доступность пункта меню
	return isAccessed;
};

export const getAccesedMenuByUserRoles = (
	items: MenuItem[],
	userRoles: ROLES[],
	isParentAccessed?: boolean
) => {
	return [...items].filter((menuItem) => {
		// Проверяем доступность пункта меню
		const isItemAccessed = getMenuItemAccess(menuItem, userRoles, isParentAccessed);

		// Если пункт меню является родительским, проверяем дочерние на доступность
		if (isMenuItemGroup(menuItem)) {
			menuItem.items = getAccesedMenuByUserRoles(
				menuItem.items,
				userRoles,
				getMenuItemParentAccess(menuItem, isItemAccessed, isParentAccessed)
			);
		}

		// Если пункт меню имеет дочерние элементы
		const hasAccessedItems = isMenuItemGroup(menuItem);

		// Если после проверки дочерних элементов есть доступные, то родительский также становится доступным
		if (hasAccessedItems && menuItem.items.length) {
			return true;
		}

		// Если после проверки нет пунктов меню, значит родительский также недоступен
		if (hasAccessedItems && !menuItem.items.length) {
			return false;
		}

		return isItemAccessed;
	});
};
