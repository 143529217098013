import type { CarbonTheme } from 'carbon-components-svelte/types/Theme/Theme.svelte';
import { writable, type Readable } from 'svelte/store';

export const APP_THEMES: CarbonTheme[] = ['white', 'g10', 'g90', 'g100'];

export const defaultTheme: CarbonTheme = 'g10';

class Store implements Readable<CarbonTheme> {
	public subscribe;

	private readonly update;

	constructor(value: CarbonTheme) {
		const { update, subscribe } = writable<CarbonTheme>(value);

		this.update = update;
		this.subscribe = subscribe;
	}

	updateTheme(value: CarbonTheme) {
		this.update(() => value);

		document.documentElement.setAttribute('theme', value);
	}
}

export const appThemeStore = new Store(defaultTheme);
