import type { DecodedTokenData } from 'shared/auth/@types/token';
import type { IUser } from 'shared/api/models/User';
import { userStore } from 'shared/stores/user';

/**
 *
 * @description Установить в стор пользователя, если есть токен, удалость декодировать и есть пользователь
 */
export const setTokenAndUserStore = (accessToken: string, tokenData: DecodedTokenData) => {
	const clearTokenData = {
		...tokenData,
		sub: JSON.parse(tokenData.sub) as IUser
	};

	userStore.setToken(accessToken, clearTokenData);

	const { sub } = clearTokenData;

	if (!sub) {
		return;
	}

	userStore.setUser(sub);
};
