import { appConfig } from 'shared/app/config';
import * as Sentry from '@sentry/svelte';
import { BrowserTracing } from '@sentry/browser';
import { indexDb } from 'shared/indexDbDexie';
import { initAppMenu } from 'features/app/menu';
import { initTheme } from 'features/app/theme';
import { initLayoutMode } from 'features/app/layout';
import { setTokenDataFromCookie } from 'shared/auth/model/user';
import moment from 'moment';
import { API } from 'shared/axios';

import 'moment/dist/locale/ru';

import 'carbon-components-svelte/css/all.css';

import './assets/styles/main.scss';

// Устанавливаем по дефолтку русский язык
moment.locale('ru');

// Initialize the Sentry SDK here
Sentry.init({
	dsn: 'https://6ac6b5fb5b314147b4d66419be505f78@sentry.ntv.ru/23',
	integrations: [new BrowserTracing()],
	enabled: glob.ENV === 'production',

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
});

// Установить в стор куки по юзеру из токена
setTokenDataFromCookie();

async function mountApp() {
	const App = await import('./App.svelte').then((data) => data.default);

	new App({
		target: document.getElementById('app') as HTMLElement
	});
}

appConfig
	.load()
	.then(async (config) => {
		API.defaults.baseURL = config.api?.new || '';

		await indexDb.init();

		initLayoutMode();

		await initTheme();

		await initAppMenu();

		mountApp();
	})
	.catch((error) => {
		// TODO: Добавить component screen для показа ошибки при загрузке приложения
		alert(error.message);
	});
