import Cookies from 'js-cookie';
import { COOKIE_KEYS_AUTH } from 'shared/config/cookie';
import type { AuthJWTCookie as AuthJWTCookieData } from './types';

class AuthJWTCookie {
	private optsCookie = { expires: 365, path: '/', domain: '.ntv.ru' };

	get tokens(): AuthJWTCookieData {
		return {
			access: Cookies.get(COOKIE_KEYS_AUTH.accessToken) || '',
			refresh: Cookies.get(COOKIE_KEYS_AUTH.refreshToken) || ''
		};
	}

	private setCookie(name: COOKIE_KEYS_AUTH, value: string) {
		Cookies.set(name, value, this.optsCookie);
	}

	setTokens(token: string, refreshToken?: string) {
		this.setCookie(COOKIE_KEYS_AUTH.accessToken, token);

		if (refreshToken) {
			this.setCookie(COOKIE_KEYS_AUTH.refreshToken, refreshToken);
		}
	}

	removeTokens() {
		Cookies.remove(COOKIE_KEYS_AUTH.accessToken, this.optsCookie);

		// HttpOnly: true, поэтому не можем взаимодействовать
		// Cookies.remove(COOKIE_KEYS_AUTH.refreshToken, this.optsCookie);
	}
}

export const authJWTCookie = new AuthJWTCookie();
