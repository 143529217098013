import { derived } from 'svelte/store';
import { isAccessRole, userRoles } from './userStore';
import { ROLES } from 'shared/config/user/roles';

export const isAuthAdmin = derived(userRoles, () => isAccessRole(ROLES.AUTH_ADMIN));
export const isAuthManager = derived(userRoles, () => isAccessRole(ROLES.AUTH_MANAGER));

export const isSpecEditor = derived(userRoles, () => isAccessRole(ROLES.SPEC_QUIZ_EDITOR));
export const isSpecEdaEditor = derived(userRoles, () => isAccessRole(ROLES.SPEC_QUIZ_EDA_EDITOR));
export const isSpecChudoEditor = derived(userRoles, () =>
	isAccessRole(ROLES.SPEC_QUIZ_CHUDO_EDITOR)
);

export const isChiefEditor = derived(userRoles, () => isAccessRole(ROLES.CMS_CHIEF_EDITOR));
