import { writable, type Readable } from 'svelte/store';

import type { AppLayoutMode, AppLoading, AppStore } from './types';

// TODO: shared/stores/stores - тофтология, изменить структуру

class Store implements Readable<AppStore> {
	public subscribe;
	private readonly update;

	constructor(value: AppStore) {
		const { update, subscribe } = writable(value);

		this.update = update;
		this.subscribe = subscribe;
	}

	showLoader(description: AppLoading['description'] = 'Загрузка...') {
		this.update((prev) => ({
			...prev,
			loading: {
				loading: true,
				description
			}
		}));
	}

	hideLoader() {
		this.update((prev) => ({
			...prev,
			loading: {
				loading: false,
				description: ''
			}
		}));
	}

	setLayoutMode(layoutMode: AppLayoutMode) {
		document.body.dataset.sidebarLayout = layoutMode || undefined;

		this.update((prev) => ({
			...prev,
			layoutMode
		}));
	}
}

export const appStore = new Store({
	layoutMode: null,
	loading: {
		loading: false
	}
});
