import type { AppConfig as AppConfigData } from './types';

class AppConfig {
	private _data: AppConfigData = { api: null, params: null, base_web_url: '' };

	get data() {
		return this._data;
	}

	private checkIsValidConfigStructure(config: AppConfigData): boolean {
		if (!config.api || !config.params) {
			return false;
		}

		if (!Array.isArray(config.api) && typeof config.api === 'object') {
			if (!config.api.new || !config.api.old) {
				return false;
			}
		}

		return true;
	}

	async load() {
		return fetch('/hub/assets/config.json').then(async (data) => {
			if (!data.ok) {
				throw new Error('Конфиг файл не найден');
			}

			const appConfigData = (await data.json()) as AppConfigData;

			const isValidConfig = this.checkIsValidConfigStructure(appConfigData);

			if (!isValidConfig) {
				throw new Error('Для конфигурационного файла приложения неправильная структура.');
			}

			this._data.api = Object.freeze(appConfigData.api);

			if (appConfigData.params) {
				this._data.params = Object.freeze(appConfigData.params);
			}

			return this.data;
		});
	}

	get authUrlParamsString(): string {
		const { params } = this.data;

		if (!params) {
			return '';
		}

		if (!('auth' in params) || typeof params.auth !== 'object') {
			return '';
		}

		const urlParams = new URLSearchParams(params.auth).toString();

		if (!urlParams) {
			return '';
		}

		return `?${urlParams}`;
	}
}

export const appConfig = new AppConfig();
